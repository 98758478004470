import React from "react"
// Components
import Seo from "../../components/seo"
import MainLayout from "../../layouts/MainLayout"

import { templateItems } from "../../components/templates/templateItems"

export default function Templates() {
  return (
    <MainLayout>
      <Seo
        title="Tailwind CSS - Templates"
        ogtitle="Tailwind CSS - Templates"
        description="Evolutionary and accessible UI components built with Tailwind"
        ogdescription="Evolutionary and accessible UI components built with Tailwind."
        author="Wind UI"
        keywords="Wind UI, Tailwind, CSS, Component, Accessible, Templates"
        robots="index, follow"
        revisit_after="7 days"
      />
      <section className="pt-16 pb-12 lg:pt-24 ">
        <header className="flex items-center">
          <div className="max-w-2xl m-auto">
            <h1 className="flex flex-col items-center justify-center pb-6 text-4xl font-semibold leading-tight text-center text-wuiSlate-900 md:text-5xl lg:text-6xl">
              Template Library
            </h1>
            <p className="pb-6 text-lg text-center">
              Explore our collection of beautifully designed themes, crafted
              with WindUI components for developers, businesses, and personal
              projects.
            </p>
          </div>
        </header>
      </section>
      <section className="pb-16 lg:pb-40">
        <div className="container">
          <div className="grid items-start grid-cols-4 gap-6 py-6 lg:grid-cols-12">
            {templateItems.map((templateItem, index) => {
              return (
                <div
                  className={`col-span-4 flex h-full flex-col justify-between overflow-visible rounded-lg bg-white shadow-xl shadow-wuiSlate-200 ring-1 ring-wuiSlate-200 transition-shadow duration-500 hover:shadow-2xl hover:shadow-wuiSlate-300 md:col-span-2 lg:col-span-4`}
                  key={index}
                >
                  <div>
                    <figure className="relative">
                      {templateItem.themeSalePriceText && (
                        <div className="absolute top-0 right-0 px-6 py-2 text-sm font-medium text-white rounded-tr-lg rounded-bl-lg bg-wuiEmerald-500">
                          {templateItem.themeSalePriceText}
                        </div>
                      )}
                      <img
                        src={templateItem.themeImage}
                        alt="card image"
                        class="aspect-video w-full"
                      />
                    </figure>
                    <header className="flex flex-col gap-1 px-6 py-4">
                      <h2 className="flex items-center justify-between text-base font-medium text-wuiSlate-700 lg:text-lg">
                        {templateItem.theme}
                        {templateItem.comingSoon && (
                          <div className="px-2 py-1 ml-auto text-xs uppercase rounded-md bg-wuiAmber-50 text-wuiAmber-500">
                            coming soon
                          </div>
                        )}
                        {templateItem.new && (
                          <div className="px-2 py-1 text-xs uppercase rounded-md bg-wuiEmerald-100 text-wuiEmerald-500">
                            new
                          </div>
                        )}
                        {templateItem.updated && (
                          <div className="px-2 py-1 text-xs uppercase rounded-md bg-wuiCyan-50 text-wuiCyan-500">
                            updated
                          </div>
                        )}
                      </h2>
                      {templateItem.categories && (
                        <div className="flex flex-row flex-wrap gap-1 text-sm font-normal capitalize [&_a]:text-slate-500 [&_a]:transition-colors hover:[&_a]:text-slate-600 [&_a]:dark:text-slate-400 hover:[&_a]:dark:text-slate-300">
                          {templateItem.categories.map((category, index) => (
                            <span key={index}>
                              {category}
                              {index !== templateItem.categories.length - 1 &&
                                ","}
                            </span>
                          ))}
                        </div>
                      )}
                    </header>
                    <div className="px-6 pb-8">
                      <p>{templateItem.themeDescription}</p>
                    </div>
                  </div>

                  {templateItem.comingSoon ? (
                    ""
                  ) : (
                    <footer className="flex flex-col gap-4 px-6 pb-8 overflow-hidden">
                      <div className="flex flex-col flex-wrap items-center gap-2 lg:flex-row lg:gap-3 2xl:flex-nowrap">
                        {templateItem.themeBuyLink && (
                          <a
                            href={templateItem.themeBuyLink}
                            target="_blank"
                            className="inline-flex items-center justify-center w-full h-10 gap-2 px-5 text-sm font-medium tracking-wide text-white transition duration-300 rounded whitespace-nowrap bg-emerald-500 hover:bg-emerald-600 focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300 disabled:shadow-none"
                          >
                            <span>
                              Get it for{" "}
                              {templateItem.themeSalePrice ? (
                                <>
                                  <span class="line-through">
                                    {templateItem.themePrice}
                                  </span>{" "}
                                  <span>{templateItem.themeSalePrice}</span>
                                </>
                              ) : (
                                templateItem.themePrice
                              )}
                            </span>
                            <span class="relative only:-mx-5">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="size-5"
                                aria-label="Button icon"
                                role="graphics-symbol"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3"
                                />
                              </svg>
                            </span>
                          </a>
                        )}
                        {templateItem.themePreviewLink && (
                          <a
                            href={templateItem.themePreviewLink}
                            target="_blank"
                            className="inline-flex items-center justify-center w-full h-10 gap-2 px-5 text-sm font-medium tracking-wide transition duration-300 border rounded whitespace-nowrap border-emerald-500 text-emerald-500 hover:border-emerald-600 hover:text-emerald-600 focus:border-emerald-700 focus:text-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:text-emerald-300 disabled:shadow-none"
                          >
                            Live Preview
                          </a>
                        )}
                      </div>
                    </footer>
                  )}
                </div>
              )
            })}
          </div>
        </div>
      </section>
    </MainLayout>
  )
}
